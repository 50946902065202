window.angular.module('MyHippoProducer.Components').directive('inputField', function () {
    return {
        restrict: 'EA',
        scope: {
            label: '@',
            isDisabled: '<',
            value: '=',
            plainText: '<',
            values: '<',
            format: '<',
            ngClick: '&?',
            step: '@',
        },
        transclude: true,
        templateUrl: 'components/input/input.html',
    };
});
